var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "container", staticClass: "container" }, [
    _c(
      "ul",
      { staticClass: "offer-list" },
      [
        !_vm.init || _vm.offerData.length
          ? [
              _vm._l(_vm.offerData, function(row, key) {
                return [
                  _c("o-item", { key: key, attrs: { row: row } }),
                  _c("div", {
                    key: key + "-line",
                    staticClass: "ui-border-line"
                  })
                ]
              })
            ]
          : _c("blank-search", { attrs: { text: "찜한 의뢰가 없습니다" } })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }