<template>
  <div class="container" ref="container">
    <ul class="offer-list">
      <template v-if="!init || offerData.length">
        <template v-for="(row, key) in offerData">
          <o-item :key="key" :row="row" />
          <div class="ui-border-line" :key="`${key}-line`"></div>
        </template>
      </template>
      <blank-search v-else text="찜한 의뢰가 없습니다" />
    </ul>
  </div>
</template>

<script>
import OItem from '@/components/client/OItem'
import BlankSearch from '@/components/blank/BlankList'

export default {
  name: 'ArtistFavOffer',
  components: {
    OItem,
    BlankSearch,
  },
  data() {
    return {
      init: false,
      offerData: [],
      offset: {
        offerData: 0,
      },
      que: {
        offerData: false,
      },
      scroll: {
        lastY: 0,
      },
    }
  },
  created() {
    this.getOfferData()
  },
  mounted() {
    this.$refs.container.addEventListener('scroll', this.getOfferDataScroll, false)
  },
  beforeRouteEnter(to, from, next) {
    next(Vue => {
      if (Vue.init) {
        Vue.$refs.container.scrollTop = Vue.scroll.lastY
      } else {
        Vue.getOfferData(true)
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'home') {
      this.scroll.lastY = 0
      this.init = false
    } else {
      this.scroll.lastY = this.$refs.container.scrollTop
    }
    next()
  },
  methods: {
    getOfferData(init) {
      if (this.que.offerData) return
      this.que.offerData = true

      if (init) this.offset.offerData = 0

      const req = {
        method: 'get',
        url: `/artist/fav/${this.offset.offerData}/20`,
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            let offerData = data.response.offerData || []
            this.offerData = init ?  offerData : this.offerData.concat(offerData)
            this.offset.offerData += offerData.length
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
        .then(() => {
          this.init = true
          setTimeout(() => {
            this.que.offerData = false
          }, 1000)
        })
    },
    getOfferDataScroll() {
      if (['ArtistFavOffer'].indexOf(this.$route.name) === -1) return

      let scrollTop = this.$refs.container.scrollTop
      let scrollHeight = this.$refs.container.scrollHeight

      if (scrollHeight <= scrollTop + window.innerHeight) {
        this.getOfferData()
      }
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
